<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Teams from "@/services/Teams";
import Swal from "sweetalert2";
import modalAddFeaturedMatch from "@/components/modals/fixtures/modalAddFeaturedMatch";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, modalAddFeaturedMatch },
  page: {
    title: "Featured",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
        csrf_token: localStorage.getItem('csrf_token'),
        title: "Featured Match",
        items: [
            {
                text: "Fixtures",
                href: "/",
            },
            {
                text: "Featured Match",
                active: true,
            },
        ],
        tableData: [],
        error: null,
        competition_id: process.env.VUE_APP_COMPETITION_ID,

        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "round",
        sortDesc: false,
        fields: [
            {
              key: "match_id",
              label: "Match ID",
            },
            {
              key: "match",
              label: "Match",
              sortable: true,
            },
            {
              key: "round",
              label: "Round",
              sortable: true,
            },
            {
              key: "match_status",
              label: "Match Status",
              sortable: true,
            },
            {
              key: "score",
              label: "Score",
              sortable: true,
            },
            {
              key: "match_date",
              label: "Match Date",
              sortable: true,
            },
            "action",
        ],
    };
  },
  middleware: "authentication",
  computed: {
      /**
       * Total no. of records
       */
      rows() {
        return this.tableData.length;
      },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created() {
      this.getFeaturedMatches()
  },
  methods: {
      /**
       * Search the table data with search input
       */
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },

      getFeaturedMatches(){
          try {
              Teams.featuredMatches(this.competition_id)
              .then(response => {
                  this.tableData = response.data.data;
              })
              .catch(error => {
                  this.error = error.response.data.error ? error.response.data.error : "";
              })
          } catch (error) {
              this.error = error.response.data.error ? error.response.data.error : "Error";
          }
      },

      confirmDelete(match_id) {
          Swal.fire({
              title: "Are you sure?",
              text: "This Match will no longer be featured !",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Yes, delete it!",
          }).then((result) => {
              if (result.value) {
                    Teams.removefeaturedMatch(match_id, {
                      csrf_token: this.csrf_token
                    })
                    .then((response) => {
                        const res = response.data.featured_match_deleted ? response.data.featured_match_deleted : false;
                        const error = response.data.error ? response.data.error : 'Failed';
                        if(res){
                            this.getFeaturedMatches(match_id);
                            Swal.fire("Removed!", "Match no longer listet as featured !", "success");
                        }else{
                            Swal.fire("Fail!", error, "warning");
                        }
                    })
                    .catch(error => {
                        Swal.fire("Fail!", error, "warning");
                    });
              }
          });
      },

      modalAddFeaturedMatch() {
          this.$bvModal.show("add_featured_match");
      },

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div>
            <button type="button" class="btn btn-success mb-3" @click="modalAddFeaturedMatch()">
                <i class="mdi mdi-plus me-1"></i> Add New Featured Match
            </button>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        
        <div class="table table-centered datatable dt-responsive nowrap table-card-list dataTable no-footer dtr-inline">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center fw-normal">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-end"
              >
                <label class="d-inline-flex align-items-center fw-normal">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ms-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <b-table
            table-class="table table-centered datatable table-card-list"
            thead-tr-class="bg-transparent"
            :items="tableData"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(match)="data">
                <p>{{data.item.home_team_name}} - {{data.item.away_team_name}}</p>
            </template>

            <template v-slot:cell(action)="data">
                <ul class="list-inline mb-0">
                  <li v-if="data.item.ticketing_url!=''" class="list-inline-item">
                      <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove from Featured" @click="confirmDelete(data.item.match_id)">
                          <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                  </li>
                </ul>
            </template>

          </b-table>

        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>


     <!-- MODALS --> 
     <modalAddFeaturedMatch @tableRefresh="getFeaturedMatches">
    </modalAddFeaturedMatch>

    <!-- END  MODALS --> 


  </Layout>
</template>
